import React, { useEffect, useState } from "react";
import { Renderer } from "@bishad/test-scope.ui.renderer";

const RenderApp = ({ hash }) => {
  const [config, setConfig] = useState({});
  const [error, setError] = useState(null);

  const getApiData = async () => {
    try {
      const response = await fetch(
        `https://config-provider.kojubishad.workers.dev/0KmNGJWYIm3uJ2hiEvCI&hash=${hash}`
      ).then((response) => response.json());
      setConfig(JSON.parse(response));
    } catch (e) {
      console.log(e);
      setError(" !!!!! ERROR :  INVALID HASH !!!!!");
    }
  };
  useEffect(() => {
    getApiData();
  }, []);

  if (!!error) {
    return <div style={{ backgroundColor: "red", color: "white", height: "30px" }}>{error}</div>;
  }
  return <Renderer config={config} />;
};

export default RenderApp;

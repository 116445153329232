import React from 'react';
import ReactDOM from "react-dom/client";
import RenderApp from "./App";

const id = "gz-booker-widget-root";
const rootElement = document.getElementById(id);

if(!rootElement){
  const e = document.createElement("div");
  document.body.appendChild(e);
  const root = ReactDOM.createRoot(e);
  root.render(<div style={{ backgroundColor: "red", color: "white", height: "30px" }}>ERROR !!! Element with id {id} not found.</div>);
}

const root = ReactDOM.createRoot(rootElement);
root.render(
  <React.StrictMode>
    <RenderApp hash={rootElement.dataset.hash} />
  </React.StrictMode>
);
